<template>
  <div class="admin-add-category">
    <error :err.sync="error" />
    <form-generator
      :elements="elements"
      :data="category"
      :handleUpdate="({ key }, v) => (category[key] = v)"
    />
  </div>
</template>

<script>
import categorySchema from "@/validation-schemas/category";
import FormGenerator from "@/components/form-generator.vue";
import { mapState } from "vuex";

export default {
  components: { FormGenerator },
  name: "admin-add-category",
  data() {
    return {
      category: {
        name: "",
        code: ""
      },
      error: null,
      loading: false
    };
  },
  computed: {
    ...mapState("ancillaries", { categories: "bookCategories" }),
    elements() {
      return [
        {
          key: "name",
          label: "name",
          type: "string"
        },
        {
          key: "code",
          label: "code",
          type: "string"
        },
        {
          text: "add-category",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.add
          }
        }
      ];
    }
  },
  methods: {
    async add() {
      this.error = null;
      const { category } = this;
      const validation = categorySchema.validate(category, this.joiOptions);
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("categories/create", {
            ...category,
            parent_category_id: category.parent_category_id
              ? category.parent_category_id.id
              : null
          });
          this.$emit("added", res.data);
          this.category = {
            name: "",
            code: ""
          };
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
