<template>
  <div class="admin-categories">
    <portal to="page-call-to-action">
      <ebp-button @click="showAddCategory = true">{{
        $t("add-category")
      }}</ebp-button>
    </portal>
    <advanced-data-table
      action="categories/paginate"
      :headers="headers"
      ref="table"
      :limit="50"
    >
      <template #cell(parent_channel)="{ item }">{{
        item.parent_channel ? item.parent_channel.name : ""
      }}</template>
      <template #cell(does_allow_free_listing)="{ item }">{{
        $t(item.does_allow_free_listing ? "yes" : "no")
      }}</template>
      <!-- Actions -->
      <template #actions="{ item }">
        <b-button
          variant="primary"
          size="sm"
          @click="edit(item)"
          v-tooltip.top-center="$t('edit')"
        >
          <i class="bx bx-pencil"></i>
        </b-button>
        <b-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          @click="remove(item)"
          v-tooltip.top-center="$t('delete')"
        >
          <i class="bx bxs-trash"></i>
        </b-button>
      </template>
    </advanced-data-table>

    <!-- SalesChannel edit modal -->
    <ebp-modal :open.sync="open" :tabs="editTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #edit-category>
        <EditCategory :category="editedItem" />
      </template>
    </ebp-modal>

    <!-- SalesChannel add modal -->
    <ebp-modal :open.sync="showAddCategory" :tabs="addTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #add-category>
        <AddCategory @added="added" />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import categorySchema from "@/validation-schemas/category";

import AddCategory from "@/views/admin/system/categories/Add";
import EditCategory from "@/views/admin/system/categories/Edit";

export default {
  name: "admin-categories",
  components: {
    AdvancedDataTable,
    AddCategory,
    EditCategory
  },
  data() {
    return {
      headers: ["name", "code"],
      editedItem: null,
      saving: false,
      open: false,
      showAddCategory: false,
      error: null,
      editTabs: ["edit-category"],
      addTabs: ["add-category"]
    };
  },
  methods: {
    added() {
      this.showAddCategory = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async remove(category) {
      if (confirm(this.$t("remove-message", { name: category.name }))) {
        try {
          await this.$store.dispatch("categories/delete", category.id);
          this.$refs.table.change("id", category.id);
        } catch (err) {
          console.error(err);
        }
      }
    },
    edit(category) {
      this.editedItem = category;
      this.open = true;
    },
    async save() {
      this.error = null;
      const category = this.editedItem;
      const validation = categorySchema.validate(category);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("categories/update", category);
          this.$refs.table.change("id", category.id, category);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
